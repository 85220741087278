import LogoMascot from '@components/partials/svg/logoMascot';
import styles from '@styles/scss/common/_contentDescription.module.scss';
import { replaceUrls } from 'lib/helper/localUrls';
import { windowWidth } from 'lib/helper/windowWidth';
import Link from 'next/link';
import { parseCookies } from 'nookies';
import { useEffect, useState } from 'react';
const languageFiles = (require as any).context('public/locales', false, /\.json$/);

export default function ContentDescription({
    siteName,
    siteUrl,
    siteLink,
    categoryName,
    content,
    contentEmail,
    extraContent,
    mascot,
    isCategory,
    currentLang
}: {
    siteName?: string;
    siteUrl?: string;
    siteLink?: string;
    categoryName?: string;
    content: string;
    contentEmail?: string;
    extraContent?: string;
    mascot?: boolean;
    isCategory?: boolean;
    currentLang: string;
}): JSX.Element {
    const languageFile = languageFiles(`./${currentLang}.json`);
    const title = languageFile.full_review.replace('%SITE_NAME%', siteName);
    const [partialText, setPartialText] = useState(true);
    const width = windowWidth();
    useEffect(() => {
        if (!isCategory || width > 550) {
            setPartialText(false);
        }
    }, [width]);
    const email = '<a href="mailto:paul@myfavsexcams.xxx">' + contentEmail + '</a>';

    const regex = /href=['"]([^'"]*)['"]/g;

    if (currentLang !== 'en') {
        content = content.replace(regex, (match, url) => replaceUrls(match, url, currentLang));
        if (extraContent) {
            extraContent = extraContent.replace(regex, (match, url) =>
                replaceUrls(match, url, currentLang)
            );
        }
    }

    const cookies = parseCookies();
    const [exidValue, setExidValue] = useState('{exid}');
    useEffect(() => {
        if (cookies.u) {
            setExidValue(cookies.u);
        }
    }, [cookies.u]);
    return (
        <>
            <div
                className={`${styles.container} ${isCategory ? styles.categoryPage : undefined}`}
                style={partialText ? { height: '450px' } : { height: 'auto' }}>
                {mascot && (
                    <div className={styles.mascot + ' banner-second'}>
                        <LogoMascot />
                    </div>
                )}
                {siteName ? <h2>{title}</h2> : null}
                <div
                    dangerouslySetInnerHTML={{
                        __html: content.replace('{email}', email).replace(/{exid}/g, exidValue)
                    }}
                    data-pint-1322="applyStyle"></div>
                {siteLink && (
                    <Link prefetch={false} href={siteLink}>
                        <a
                            className={styles.visitButton}
                            data-site-name={siteName}
                            data-site-category={categoryName}
                            target="_blank"
                            rel="noopener noreferrer">
                            {languageFile.click_visit}
                        </a>
                    </Link>
                )}
                {extraContent && (
                    <div className={styles.extraContent}>
                        <div className={styles.heading}>Links of Interest</div>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: extraContent
                                    .replace('{email}', email)
                                    //.replace(/\/images/g, 'https://assets.myfavsexcams.xxx/images')
                                    .replace(/<img\s[^>]*?src\s*=\s*['"]([^'"]*?)['"][^>]*?>/g, '')
                                    .replace(/{exid}/g, exidValue)
                            }}></p>
                    </div>
                )}
            </div>
            {partialText && (
                <div className={styles.buttonsBox}>
                    <div className={styles.readMoreFading}></div>
                    <div
                        className={styles.readMoreButton + ' pointer'}
                        role="button"
                        aria-hidden="true"
                        onClick={() => setPartialText(false)}
                        onKeyDown={() => setPartialText(false)}>
                        Continue Reading
                    </div>
                    {siteLink && (
                        <Link prefetch={false} href={siteLink}>
                            <a
                                className={styles.visitButton}
                                data-site-name={siteName}
                                data-site-category={categoryName}
                                target="_blank"
                                rel="noopener noreferrer">
                                {languageFile.click_visit}
                            </a>
                        </Link>
                    )}
                    {extraContent && (
                        <div className={styles.extraContent}>
                            <div className={styles.heading}>Links of Interest</div>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: extraContent
                                        .replace('{email}', email)
                                        //.replace(/\/images/g, 'https://assets.myfavsexcams.xxx/images')
                                        .replace(
                                            /<img\s[^>]*?src\s*=\s*['"]([^'"]*?)['"][^>]*?>/g,
                                            ''
                                        )
                                        .replace(/{exid}/g, exidValue)
                                }}></p>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
