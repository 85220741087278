export default function svgTrending(): JSX.Element {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 908.13 731.8">
            <title>icon-trending2</title>
            <path
                d="M33.64,730.57l-18.83-15L185.23,497.36,355.9,278.79l99.56,77.74,99.88,78L678.92,276.3c68-87,123.38-159.7,123.13-161.76-.22-1.76-17.76-16-38.76-31.58s-37.84-29-37-29.71c1.84-2,188-41.31,189.37-40,1,.77,2.78,42,4.15,91.87C921.44,155,922.86,198,922.93,201c.47,3.81-11.77-3.62-39.35-24.36l-40-29.65L703.79,326,564,505l-99.88-78-99.56-77.74L210.12,547c-85,108.79-155,197.88-155.93,198S44.15,738.51,33.64,730.57Z"
                transform="translate(-14.81 -13.23)"
                fill="#37a4dd"
            />
        </svg>
    );
}
