import { getTosMenuList } from '@data/tosMenuList';
import classes from '@styles/scss/common/_footer.module.scss';
//import { useRouter } from 'next/router';

export default function Footer(): JSX.Element {
    const tosList: any = getTosMenuList();
    const currentYear = new Date().getFullYear();
    return (
        <>
            <div className={classes.copy}>
                {Object.keys(tosList.en).map((tosTabName: any) => {
                    return (
                        <span key={tosTabName}>
                            <a
                                href={'/tos' + '#' + tosList.en[tosTabName].tab}
                                id={tosTabName}
                                aria-label={tosList.en[tosTabName].title}
                                className={classes.inftabs + ' ' + classes.link}
                                data-original-title={tosList.en[tosTabName].title}>
                                {tosList.en[tosTabName].title}
                            </a>{' '}
                            |{' '}
                        </span>
                    );
                })}
                <a
                    data-rel="tabprivacy"
                    className={classes.inftabs + ' ' + classes.link}
                    href={'https://www.myfavsexcams.xxx/blog/'}
                    title="Sex Cam BLOG Articles"
                    data-original-title="Blog">
                    Sex Cam BLOG Articles
                </a>{' '}
                | © {currentYear} My Fav Sex Cams - All Rights Reserved.{' '}
            </div>
        </>
    );
}
