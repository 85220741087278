export function replaceUrls(match: string, url: string, currentLang: string): string {
    if (
        url.includes('www.myfavsexcams.xxx/') &&
        !url.includes('/blog/') &&
        !url.includes('/out/')
    ) {
        const localizedUrl = url.replace(
            'www.myfavsexcams.xxx/',
            `www.myfavsexcams.xxx/${currentLang}/`
        );
        return `href="${localizedUrl}"`;
    } else {
        return match;
    }
}
