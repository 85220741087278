export default function svgReadReview(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="85" height="42" viewBox="0 0 85 42">
            <path
                stroke="#999"
                strokeWidth="6"
                d="M45 3h39.5M45.063 39.25h39.5M55.25 27.25H84.5M55.313 15h29.25"
            />
            <path
                fillRule="evenodd"
                fill="#999"
                clipRule="evenodd"
                d="M26.148.813l19.56 19.56c.258.258.172.527-.09.79L26.063 40.717c-.264.264-.444.26-.703.002l-4.32-4.32 12.333-12.332-33.248.087L0 17.462h33.287L20.947 5.12 25.264.807c.262-.263.624-.256.885.006z"
            />
        </svg>
    );
}
